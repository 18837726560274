import scrollToElement from "scroll-to-element";

export const scrollInicio = () => {
    window.scrollTo(0, 0);
}

export const scrollPresenca = () => {
    scrollToElement('#Presenca', {
        offset: -410,
        duration: 500
    });
}

export const scrollPresentes = () => {
    scrollToElement('#Presentes', {
        offset: -310,
        duration: 500
    });
}

export const scrollLocalizacao = () => {
    scrollToElement('#Localizacao', {
        offset: -100,
        duration: 500
    });
}

export const scrollPlaylist = () => {
    scrollToElement('#Playlist', {
        offset: -85,
        duration: 500
    });
}

export const scrollRegras = () => {
    scrollToElement('#Regras', {
        offset: -95,
        duration: 500
    });
}

