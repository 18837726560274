import Grid from '@mui/material/Grid';

export const BgHeader = () => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <img src='./assets/backgrounds/01-header.png' alt='Logo+Bru+Leo.png' width={'100%'} height={'100%'}></img>
            </Grid>
        </Grid >
    );
}

