import { Grid } from '@mui/material';
import { AppHeader } from '../../components/AppHeader/AppHeader';
import { Localizacao } from '../../components/Localizacao/Localizacao';
import { Presenca } from '../../components/Presenca/Presenca';
import { BgHeader } from '../../components/BgHeader/BgHeader';
import { Playlist } from '../../components/Playlist/Playlist';
import { Regras } from '../../components/Regras/Regras';
import { Presentes } from '../../components/Presentes/Presentes';

export const Index = () => {
    document.addEventListener('contextmenu', event => event.preventDefault());

    return (
        <Grid container>
            <Grid item xs={12} id='inicio'>
                <AppHeader />
                <BgHeader />
            </Grid>

            <Grid item xs={12} id='Presentes'>
                <Presentes />
            </Grid>
            
            <Grid item xs={12} id='Presenca'>
                <Presenca />        
            </Grid>
            
            <Grid item xs={12} id='Localizacao'>
                <Localizacao />
            </Grid>

            <Grid item xs={12} id='Playlist'>
                <Playlist />
            </Grid>

            <Grid item xs={12} id='Regras'>
                <Regras />
            </Grid>
        </Grid>
    )
}
