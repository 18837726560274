import { AppBar, Button, Grid, Box, Typography } from "@mui/material"
import { BgHeader } from "../../components/BgHeader/BgHeader"
import { useNavigate } from 'react-router-dom';
import styled from "@emotion/styled";

const NotFoundBox = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
}));

const NotFoundContainer = styled(Grid)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#000000'
}));

export const NotFound = () => {
    document.addEventListener('contextmenu', event => event.preventDefault());
    const navigate = useNavigate();

    const navigateToHome = () => {
        return navigate('/');

    }
    return (

        <NotFoundContainer container minHeight={'100vh'}>
            <Grid item xs={10} sm={8} md={7} lg={6} xl={5}>
                <NotFoundBox>
                    <Typography variant='h5' color='info' sx={{ fontWeight: '800', alignSelf: 'center'}}>
                        404
                    </Typography>
                    
                    <img src="./assets/backgrounds/logo.png" alt="logo" width='200px' />
                    
                    <Typography variant='h6' color='info' sx={{ fontWeight: '800', pb: 2, alignSelf: 'center' }}>
                        Page not found
                    </Typography>
                
                    <Button variant='contained' color='info' onClick={navigateToHome}>
                        <Typography variant='body1'>
                            Home
                        </Typography>
                    </Button>
                </NotFoundBox>
            </Grid>
        </NotFoundContainer>
    )
}
