import { Grid, Typography, Link, Box } from "@mui/material"

export const Regras = () => {
    return (
        <Grid container display='flex' justifyContent='center' alignContent='flex-start'
            alignItems='flex-start' flexDirection='row' bgcolor='#000000' sx={{ pt: { xs: 6, md: 6 } }}>
            <Grid item xs={10} display='flex' flexDirection='column' justifyContent='center'
                alignContent='center' alignItems='center'>

                <Grid item xs={12} md={8} display='flex' flexDirection='column' bgcolor='#000000' justifyContent='center' flexWrap={'wrap'}>
                    <img src="./assets/titulos/05-noivos-chatos-title.png" alt="playlist_title" width='100%' />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant='h5' align="center" sx={{ fontWeight: '700', pb: 4 }}>
                        Estabelecemos alguns pequenos limites para nossa cerimônia:
                    </Typography>

                    <Typography variant='h6' align="center">
                        - Dress code: Esporte fino.
                    </Typography>
                    <Typography variant='subtitle1' align="center" sx={{ mt: -1, mb: 1 }}>
                        *Se você tiver dúvidas,
                        <Link target="_blank" href="https://www.fildihotel.com.br/tipos-de-trajes-saiba-qual-o-ideal-para-cada-tipo-de-evento/"
                            color="inherit" underline="none" rel="noopener"
                            sx={[{ fontWeight: '700' }, { '&:hover': { color: '#800a05', } }]}> clique aqui </Link>
                        ou peça por socorro.
                    </Typography>

                    <Typography variant='h6' align="center" sx={{ mb: 1 }}>
                        - Favor evitar roupas nas tonalidades:
                        <Typography variant='h6' sx={{ color: '#FFFFFF', display: 'inline' }}> branco</Typography>,
                        <Typography variant='h6' sx={{ color: '#A94B00', display: 'inline' }}> marrom</Typography>,
                        <Typography variant='h6' sx={{ color: '#B84030', display: 'inline' }}> terracota</Typography>,
                        <Typography variant='h6' sx={{ color: '#006400', display: 'inline' }}> verde</Typography>, 
                        <Typography variant='h6' sx={{ color: '#800000', display: 'inline' }}> bordô </Typography>
                        e <Typography variant='h6' sx={{ color: '#6D012B', display: 'inline' }}>vinho</Typography>.
                    </Typography>

                    <Typography variant='h6' align="center">
                        - Convidado não convida.
                    </Typography>
                    <Typography variant='subtitle1' align="center" sx={{ mt: -1, mb: 1 }}>
                        *Básico né?
                    </Typography>

                    <Typography variant='h6' align="center">
                        - Os itens da festa devem permanecer na festa.
                    </Typography>
                    <Typography variant='subtitle1' align="center" sx={{ mt: -1, mb: 1 }}>
                        *Haverá lembrancinhas para cada convidado, portanto deixe as taças, copos, guardanapos, arranjos e afins na festa, ok?
                    </Typography>

                    <Typography variant='h6' align="center">
                        - Se precisar de carona para voltar, respeite o horário combinado.
                    </Typography>
                    <Typography variant='subtitle1' align="center" sx={{ mt: -1, mb: 1 }}>
                        *Ou prepare-se para uma caminhada noturna inesquecível.
                    </Typography>

                    <Typography variant='h6' align="center">
                        - Solicitamos que não entrem nas áreas internas sem permissão.
                    </Typography>
                    <Typography variant='subtitle1' align="center" sx={{ mt: -1, mb: 1 }}>
                        *Somos um casal de noivos, não seguranças.
                    </Typography>

                   <Typography variant='h6' align="center">
                        - Quem não gosta de uma surpresa romântica durante um casamento?
                    </Typography>
                    <Typography variant='subtitle1' align="center" sx={{ mt: -1, mb: 1}}>
                        *Nós! Por isso, deixe seus pedidos de casamento/namoro para outro momento.
                    </Typography>

                    <Typography variant='h6' align="center" sx={{ mb: 1 }}>
                        - Não adote um garçom como seu assistente pessoal.
                    </Typography>

                    <Typography variant='h6' align="center">
                        - Por favor, evite desperdício de comida.
                    </Typography>
                    <Typography variant='subtitle1' align="center" sx={{ mt: -1, mb: 1 }}>
                        *O menu foi feito com muito carinho.
                    </Typography>

                    <Typography variant='h6' align="center" sx={{ mb: 1 }}>
                        -Pode ser um pouco tentador, mas evite ficar grudado nos noivos, eles mordem.
                    </Typography>

                    <Typography variant='h6' align="center">
                        - Não ofereça comida aos animais da chácara.
                    </Typography>
                    <Typography variant='subtitle1' align="center" sx={{ mt: -1, pb: 8 }}>
                        *Eles têm seu próprio banquete.
                    </Typography>
                </Grid>
            </Grid>
        </Grid >
    )
}
