import { Grid, Typography, Link, Button } from "@mui/material"

export const Presentes = () => {
    return (
        <Grid container display='flex' justifyContent='center' alignContent='flex-start'
            alignItems='flex-start' flexDirection='row' bgcolor='#000000' sx={{ py: { xs: 8, md: 6 } }}>
            <Grid item xs={12} display='flex' flexDirection='column' justifyContent='center'
                alignContent='center' alignItems='center'>

            <Grid item xs={12} md={4} display='flex' flexDirection='column' bgcolor='#000000' justifyContent='center' flexWrap={'wrap'}>
                <img src="./assets/titulos/06-lista-de-presentes.png" alt="presentes_title" width='100%' />             
            </Grid>


                <Grid item xs={12} sx={{ pb: 4}}>
                    <Button variant='contained' color='info' sx={{ p: 1.4 }}>
                        <Link target="_blank" href="https://www.amazon.com.br/hz/wishlist/ls/1HWHX8DKBOR1H?ref_=wl_share"
                            color="inherit" underline="none" rel="noopener"
                            sx={{ fontWeight: '700'}}> lista de presentes </Link>
                    </Button>
                </Grid>
                    
            </Grid>
        </Grid >
    )
}
