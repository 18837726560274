import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Index } from "../pages/Index/Index";
import { NotFound } from "../pages/NotFound/NotFound";

export const AppRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Index />} />
                <Route path='*' element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    );
}