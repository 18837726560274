import { AppBar, Grid, Button, Typography } from '@mui/material'
import { scrollInicio, scrollLocalizacao, scrollPlaylist, scrollPresenca, scrollPresentes, scrollRegras } from '../../utils/functions/scrollToFunctions/scrollTo'

export const AppHeader = () => {

    return (
        <AppBar color="transparent" elevation={0} sx={{
            display: { xs: 'none', md: 'block' },
            p: { md: 8 }
        }}>
            <Grid container display={'flex'} flexDirection={'row'}>
                <Grid item xs={12} display={'flex'} flexWrap={'wrap'} alignItems={'center'}
                    alignContent={'center'} justifyContent={'center'} columnGap={5}>
                    <Button variant='text' color='info' sx={{ p: 1.4 }} onClick={scrollInicio}>
                        <Typography variant='body1'>
                            início
                        </Typography>
                    </Button>

                    <Button variant='text' color='info' sx={{ p: 1.4 }} onClick={scrollPresentes}>
                        <Typography variant='body1' color='info' sx={{ fontWeight: '800' }}>
                            presentes
                        </Typography>
                    </Button>

                    <Button variant='text' color='info' sx={{ p: 1.4 }} onClick={scrollPresenca}>
                        <Typography variant='body1' color='info' sx={{ fontWeight: '800' }}>
                            lista de presença
                        </Typography>
                    </Button>

                    <Button variant='text' color='info' sx={{ p: 1.4 }} onClick={scrollLocalizacao}>
                        <Typography variant='body1'>
                            como chegar
                        </Typography>
                    </Button>

                    <Button variant='text' color='info' sx={{ p: 1.4 }} onClick={scrollPlaylist}>
                        <Typography variant='body1'>
                            nossa playlist
                        </Typography>
                    </Button>

                    <Button variant='text' color='info' sx={{ p: 1.4 }} onClick={scrollRegras}>
                        <Typography variant='body1'>
                            regras
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </AppBar >
    )
}
