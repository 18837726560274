import { Box } from '@mui/material';
import { useMemo } from 'react'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

export const Map = () => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyArt2TVn9uQ8CSAZAOdDaqveY7QuNYiN4A"
    });

    const location = useMemo(() => ({
        lat: -25.331616973749735,
        lng: -49.00468090795548
    }), []);

    return (
        <Box sx={{
            width: {
                xs: '350px', sm: '400px', md: '450px', lg: '550px', xl: '750px'
            },
            height: {
                xs: '350px', sm: '300px', md: '400px', lg: '400px', xl: '500px'
            }
        }}>
            {
                isLoaded ? (
                    <GoogleMap
                        mapContainerStyle={{ width: '100%', height: '100%' }}
                        center={location}
                        zoom={16}
                    >

                        <Marker position={location}
                            options={{
                                title: 'Chácara Massala',
                            }} />
                    </GoogleMap >
                ) : <></>}
        </Box >
    )
}