import styled from "@emotion/styled";
import { Grid, Typography, Link } from "@mui/material"
import { Spotify } from 'react-spotify-embed'
import './playlist.css'

const PlaylistGrid = styled(Grid)(() => ({
    backgroundImage: 'url(./assets/backgrounds/03-playlist-e-regras.png)',
    backgroundSize: 'cover',
    height: '100vh',
}));

export const Playlist = () => {
    return (
        <PlaylistGrid container display='flex' flexDirection='row' sx={{ pt: 8, pb: 8 }}>
            <Grid item xs={12} md={6} display='flex' flexDirection='column'
                justifyContent='center' alignContent='center' flexWrap={'wrap'}>

                <img src="./assets/titulos/03-playlist-title.png" alt="playlist_title" width='100%' />

                <Typography variant='h6' align="center" sx={{ pb: 3 }}>
                    <Link target="_blank" href="https://open.spotify.com/playlist/70sfv5RUMHz4DFk6xkwUrz?si=2d7c78ccee0a4236&pt=56003d839c0405fe203df1eaa20dbcf0"
                        color="inherit" underline="none" rel="noopener"
                        sx={{ '&:hover': { color: '#B84030', } }}>Clique aqui </Link>
                    e paticipe da playlist!
                </Typography>
                <Typography variant='h6' align="center">
                    Adicione musicas para a
                </Typography>
                <Typography variant='h6' align="center">
                    gente ouvir nesse momento tão
                </Typography>
                <Typography variant='h6' align="center">
                    especial de planejamento.
                </Typography>

            </Grid>

            <Grid item xs={12} md={6} display='flex' flexDirection='column' justifyContent='center'
                alignContent='center' flexWrap={'wrap'} sx={{ pt: 8, p: 2 }}>
                <Spotify link="https://open.spotify.com/playlist/70sfv5RUMHz4DFk6xkwUrz?si=a24cd4dce3d94d1b" />
            </Grid>
        </PlaylistGrid >
    )
}
