import { Checkbox, FormControlLabel, Grid, Paper, Typography, styled, Box } from "@mui/material";
import { MeuInput } from "../MeuInput/MeuInput";
import { useState } from "react";
import { MeuBotao } from "../MeuBotao/MeuBotao";
import { Convidados } from "../../services/classes/convidados/convidados";
import { IConvidado } from "../../interfaces/IConvidado/IConvidado";
import { MeuAlert } from "../MeuAlert/MeuAlert";

const MeuPaper = styled(Paper)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    background: '#000000',
    filter: 'drop-shadow(0px 0px 6px #B84030)',
}));

export const Presenca = () => {
    const [nome, setNome] = useState<string>("");
    const [transporte, setTransporte] = useState(false);
    const [alerta, setAlerta] = useState(false);
    const [mensagemAlerta, SetMensagemAlerta] = useState('');
    const [tituloAlerta, SetTituloAlerta] = useState('');
    const [alertSeverity, SetAlertSeverity] = useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTransporte(event.target.checked);
    };

    const alertaPresenca = () => {
        setAlerta(true);

        setTimeout(() => {
            setAlerta(false);
        }, 3500);
    }

    async function confirmarClick() {
        if (!nome) {
            SetTituloAlerta('Nome não preenchido!');
            SetMensagemAlerta('Você precisa digitar seu nome.');
            SetAlertSeverity(false);
            limparCampos();
            return alertaPresenca()
        }

        const convidado: IConvidado = {
            nome,
            transporte
        }

        const resposta = await Convidados.confirmarPresenca(convidado);
        SetMensagemAlerta(resposta.data.mensagem);
        SetTituloAlerta(resposta.data.dados);

        if (resposta.data.sucesso) {
            SetAlertSeverity(true);
            alertaPresenca();
            return limparCampos();
        }

        SetTituloAlerta('Verifique o nome digitado');
        SetAlertSeverity(false);
        alertaPresenca();
        limparCampos();
    }

    function limparCampos() {
        setNome('');
        setTransporte(false);
    }

    return (
        <Grid container display='flex' justifyContent='center' alignContent='center' alignItems='center' flexDirection='column' padding={4} bgcolor='#000000'>
            
            <Grid item xs={12} md={6} display='flex' flexDirection='column' bgcolor='#000000' justifyContent='center' flexWrap={'wrap'}>
                    <img src="./assets/titulos/01-confirme-sua-presença-title.png" alt="playlist_title" width='100%' />
            </Grid>

            <Grid item xs={12} md={6} display='flex' flexDirection='column' justifyContent='center' bgcolor='#000000'>
                <MeuPaper elevation={0} sx={{ mt: { xs: 4, md: 0 }, px: { xs: '32px', lg: '76px' }, py: { xs: '24px', lg: '38px' } }}>
                    <Typography variant='body2'>
                        Escreva o nome igual ao do convite:
                    </Typography>
                    <MeuInput
                        label='Nome' type='nome' variant='outlined'
                        placeholder='Nome' color='info'
                        size='medium' value={nome}
                        onChange={(e) => setNome(e.target.value)}
                    />

                    {alerta && <MeuAlert titulo={tituloAlerta}
                        mensagem={mensagemAlerta}
                        severity={alertSeverity ? 'success' : 'error'}
                    />}
                    <FormControlLabel control={<Checkbox color='info' checked={transporte}
                        onChange={handleChange} />} label="Preciso de transporte" />

                    <MeuBotao
                        texto='confirmar presença' color='info'
                        size='medium' variant='contained'
                        onClick={confirmarClick}
                    />
                </MeuPaper>
            </Grid>
        </Grid>
    )
}
