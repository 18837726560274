import './config/styles/scrollbar.css'
import { CssBaseline } from "@mui/material";
import { AppRoutes } from "./routes/routes";
import { ThemeProvider } from '@mui/material/styles';
import { Theme } from "./config/theme/theme";


function App() {
  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <AppRoutes />
    </ThemeProvider>
  );
}

export default App;
