import styled from "@emotion/styled";
import { Grid, Typography, Box } from "@mui/material"
import { Map } from "../Map/Map";

const LoclizacaoGrid = styled(Grid)(() => ({
    backgroundImage: 'url(./assets/backgrounds/02-como-chegar.png)',
    backgroundSize: 'cover',
    height: '100vh',
}));

export const Localizacao = () => {
    return (
        <LoclizacaoGrid container display='flex' flexDirection='row'>
            <Grid item xs={12} md={6} display='flex' flexDirection='column' justifyContent='center' alignContent='center' flexWrap={'wrap'} sx={{ pt: 2 }}>

                <img src="./assets/titulos/02--como-chegar-tittle.png" alt="localizazao_title" width='100%' />
                
                <Typography variant='h4' align="center">
                   Chácara Massala
                </Typography>

                <Typography variant='h6' align="center">
                    Rua Nossa Senhora da Luz, 540 -
                </Typography>

                <Typography variant='h6' align="center">
                    Palmitalzinho, Quatro Barras - PR
                </Typography>

            </Grid>

            <Grid item xs={12} md={6} display='flex' flexDirection='column' justifyContent='center'
                alignContent='center' flexWrap={'wrap'} sx={{ pt: 2 }}>
                <Box>
                    <Map />
                </Box>
            </Grid>
        </LoclizacaoGrid >
    )
}



