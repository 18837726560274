import { createTheme } from '@mui/material';
import { ptBR } from '@mui/material/locale';

export const Theme = createTheme(
    {
        typography: {
            fontFamily: 'Montserrat',
        },
        palette: {
            mode: 'dark',
            primary: {
                main: '#000000'
            },

            secondary: {
                main: '#800a05'
            },

            error: {
                main: '#800a05'
            },

            warning: {
                main: '#800a05'
            },

            info: {
                main: '#fafafa'
            },

            success: {
                main: '#7d8a22'
            },
        },
    },
    ptBR
);