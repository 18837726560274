import { IConvidado } from "../../../interfaces/IConvidado/IConvidado";
import { api } from "../../api/api";

export class Convidados {
    static async confirmarPresenca(objeto: IConvidado) {
        try {
            const resposta = await api.put(`/convidados/presenca`, objeto);
            return resposta;
        } catch (error: any) {
            return error.response;
        }
    }
}